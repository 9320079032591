import React from "react"
import { graphql, Link } from "gatsby"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderProjekte from "../components/header_projekte"
import Carousel from "../components/carousel"

const NewsTemplate = ({ data }) => {
  const news = data.strapiNew

  return (
    <Layout>
    <SEO title={news.title} />
      <div className="header-nav">
        <ul>
          <li style={{ opacity: "0" }}>&nbsp;</li>
        </ul>
      </div>

    <Carousel images={ news.pictures } />
    <h3>{ news.title }</h3>
    <Reactmarkdown>{ news.content }</Reactmarkdown>

    <a href="/">Zurück zur Startseite</a>

  </Layout>
  )
}

export default NewsTemplate

export const query = graphql`
  query NewsQuery($slug: String!) {
    strapiNew(slug: {eq: $slug}) {
      title
      content
      pictures {
        localFile {
          childImageSharp {
            fixed(height: 550) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      } 
    }
  }
`